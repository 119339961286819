/*
 *
 * The content of this file can be edited freely, but to maintain upgradability
 * this file should not be renamed and should always export an Angular module named
 * `AppRoutingModule`.
 *
 *
 */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EntitlementsGuard } from '@backbase/foundation-ang/entitlements';
import { AuthGuard } from '@backbase/shared/feature/auth';
import { SharedUserContextGuard } from '@backbase/shared/feature/user-context';
import { PERMISSIONS } from './auth/permissions';
import { LayoutComponent } from './layout/layout.component';
import { IdentityJourneyWrapperComponent } from './journeys/wrapper-component/journey-wrapper.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'accounts',
  },
  {
    path: 'select-context',
    loadChildren: () => import('./user-context/user-context.module').then((m) => m.UserContextModule),
    canActivate: [AuthGuard],
    title: $localize`:@@context-selection.nav.item.title:Select Context`,
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'accounts',
      },

      /**
       * Accounts
       */
      {
        path: 'accounts',
        loadChildren: () =>
          import('./journeys/accounts/accounts-journey-bundle.module').then((m) => m.AccountsJourneyBundleModule),
        data: {
          entitlements: PERMISSIONS.canViewAccounts,
        },
        title: $localize`:@@accounts.nav.item.title:Accounts`,
      },
      {
        path: 'account-statements',
        loadChildren: () =>
          import('./journeys/account-statements/custom-account-statement-journey-bundle.module').then(
            (m) => m.CustomAccountStatementJourneyBundleModule,
          ),
        data: {
          entitlements: PERMISSIONS.canViewAccountStatements,
          redirectTo: 'accounts',
        },
        title: $localize`:@@account-statements.nav.item.title:Account Statements`,
      },
      {
        path: 'digital-certificates',
        loadChildren: () =>
          import('./journeys/digital-certificates/digital-certificates-journey-bundle.module').then(
            (m) => m.DigitalCertificatesJourneyBundleModule,
          ),
        title: $localize`:@@digital-certificates.nav.item.title:Digital Certificates`,
      },
      {
        path: 'cards',
        loadChildren: () =>
          import('./journeys/cards/cards-journey-bundle.module').then((m) => m.CardsJourneyBundleModule),
        title: $localize`:@@digital-certificates.nav.item.title:Digital Certificates`,
      },
    ],
    canActivate: [AuthGuard, SharedUserContextGuard],
    canActivateChild: [EntitlementsGuard],
  },
  {
    path: 'logout',
    component: IdentityJourneyWrapperComponent,
    children: [
      {
        path: 'username-success',
        loadChildren: () =>
          import('./journeys/self-service/bundle-change-username.module').then(
            (m) => m.ChangeUsernameSuccessBundleModule,
          ),
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'accounts',
  },
];

@NgModule({
  declarations: [IdentityJourneyWrapperComponent],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
