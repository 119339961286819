import { AmountConfig } from '@backbase/ui-ang/amount';

export interface AppLevelConfig {
  paginationType: 'pagination' | 'load-more' | string;
  pageSize: number;
  transactionStatementMaxNavPages: number;
  arabianLocale: string[];
  notificationTtl: number;
  tooManyRecordsErrorKey: string;
  dateFormat: string;
  noPageLimit:number;
  manageAccountStatenentMaxNavPages:number;
  [key: string]: unknown;
}

export const APP_CONFIG: AppLevelConfig = {
  paginationType: 'pagination',
  pageSize: 20,
  transactionStatementMaxNavPages: 5,
  arabianLocale: ['ar', 'ar-SA'],
  notificationTtl: 5000,
  tooManyRecordsErrorKey: '703132',
  dateFormat: 'dd/MM/yyyy',
  noPageLimit:9999,
  manageAccountStatenentMaxNavPages:3
};

export const AMOUNT_CONFIG: AmountConfig = {
  signPosition: 'right',
};
